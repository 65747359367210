import { API_BASE } from "../../config/env";
import axios from "axios";
export const FETCH_GETSTATISTIC_FULFILLED = "FETCH_GETSTATISTIC_FULFILLED";
export const FETCH_GETSTATISTIC_REJECTED = "FETCH_GETSTATISTIC_REJECTED";
export const FETCH_GETSTATISTIC_PENDING = "FETCH_GETSTATISTIC_PENDING";

export function getStatistic(gameId, userId, startDate, endDate) {
    const token = localStorage.getItem("accessToken")
    return (dispatch) => {
        dispatch({
            type: "FETCH_GETSTATISTIC",
            payload: axios({
                method: "post",
                url: `${API_BASE}/api/game-statistics`,
                data: {
                    gameId,
                    userId,
                    startDate,
                    endDate
                },
                headers: {
                    "Accept-Language": "tr-tr",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                responseType: 'blob', // Excel dosyasını blob olarak almak için
            }).then((response) => {
                // Excel dosyasını indirme işlemi
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'rapor.xlsx');
                document.body.appendChild(link);
                link.click();
                link.remove();
                return response.data;
            })
        });
    };
}





