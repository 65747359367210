import { Routes, Route, useLocation, Navigate, useNavigate, BrowserRouter } from "react-router-dom";
import './App.css';
import { connect } from "react-redux";
import Index from './components/dashboard'
import Login from "./components/auth/login";
import PatientStatistic from "./components/dashboard/patientStatistic";
import PatientGame from "./components/dashboard/patientGames"
import Genesis from "./components/auth/genesis";
import GameDetailTK from "./components/dashboard/gameDetailTK";
import VerifyCode from "./components/auth/verifyCode";
import PatientSessions from "./components/dashboard/patientSessions";
import Reports from "./components/reports/Reports";
function App(props) {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Index />}></Route>
        <Route path="/login" element={<Login/>}></Route>
        <Route path="/register-phone" element={<Genesis/>}></Route>
        <Route path="/verify-code" element={<VerifyCode/>}></Route>
        <Route path="/patient-games/:Id" element={<PatientGame />}></Route>
        <Route path="/patient-game-sessions/:GameId/:UserId" element={<PatientSessions />}></Route>
        <Route path="/game-statistic-detail/:Id/:UserId/:Name" element={<GameDetailTK />}></Route>
        <Route path="/reports" element={<Reports />}></Route>
        
      </Routes>
    </div>
  );
}

const mapStateToProps = (state) => {
  return state;
};
const mapDispatchToProps = {

};
export default connect(mapStateToProps, mapDispatchToProps)(App);
