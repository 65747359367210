import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import Header from '../layout/header';
import { getGameByUserId } from '../../actions/game/getGameByUserId';
import GameCard from '../cards/GameCard';

function PatientGames(props) {
    const { Id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getGameByUserId(Id))
    }, [dispatch])
    console.log(props)

    return (
        <div>
            <Header />
            <div className="container">
                <div className="row">
                    {props.getGameByUserId.done ?
                        props.getGameByUserId.spinner ?
                            <div className="d-flex align-items-center mt-4">
                                <strong>Yükleniyor...</strong>
                                <div className="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                            </div>
                            :
                            props.getGameByUserId.getGameByUserId.result.map((element, index) =>
                                <GameCard
                                    key={index}
                                    name={element.Name}
                                    tags={element.Tag}
                                    detail={element.Detail}
                                    gameid={element.Id}
                                    userid={Id}
                                />
                            )
                        :
                        props.getGameByUserId.spinner ?
                            <div className="d-flex align-items-center mt-4">
                                <strong>Yükleniyor...</strong>
                                <div className="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                            </div>
                            :
                            <div className="alert alert-danger mt-4" role="alert">
                                <h4 className="alert-heading">Üzgünüz!</h4>
                                <p>Bu hasta henüz oyunlardan herhangi birini oynamamıştır.</p>
                                <hr />
                                <p className="mb-0">Bir yanlışlık olduğunu düşünüyorsanız lütfen <a href="https://www.guvenfuture.com/">bizimle iletişime geçiniz.</a></p>
                            </div>
                    }
                    {/* {
                        props.getAssignment.spinner ?
                            <div className="d-flex align-items-center mt-4">
                                <strong>Yükleniyor...</strong>
                                <div className="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                            </div>
                            : null
                    } */}


                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PatientGames);
