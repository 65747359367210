import { API_BASE } from "../../config/env";
import axios from "axios";
export const FETCH_GETGAMES_FULFILLED = "FETCH_GETGAMES_FULFILLED";
export const FETCH_GETGAMES_REJECTED = "FETCH_GETGAMES_REJECTED";
export const FETCH_GETGAMES_PENDING = "FETCH_GETGAMES_PENDING";

export function getGames() {
    const token = localStorage.getItem("accessToken")
    return (dispatch) => {
        dispatch({
            type: "FETCH_GETGAMES",
            payload: axios({
                method: "get",
                url: `${API_BASE}/api/game/get`,
                headers: {
                    "Accept-Language": "tr-tr",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            }).then((result) => result.data)
        });
    };
}





