export function ConvertDate(isoTarih) {
    const aylar = [
        'Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran',
        'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'
    ];

    const gunler = [
        'Pazar', 'Pazartesi', 'Salı', 'Çarşamba',
        'Perşembe', 'Cuma', 'Cumartesi'
    ];

    const tarih = new Date(isoTarih);
    const gun = tarih.getUTCDate();
    const ay = aylar[tarih.getUTCMonth()];
    const haftaninGunu = gunler[tarih.getUTCDay()];
    const saat = tarih.getUTCHours();
    const dakika = tarih.getUTCMinutes().toString().padStart(2, '0');

    return `${gun} ${ay} ${haftaninGunu} ${saat}:${dakika}`;
}



