import React, { useEffect } from 'react'
import Swal from 'sweetalert2'
import PatientStatistic from '../dashboard/patientStatistic'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

function patientcard(props) {
   
    return (
        <div className='col-sm-12 col-md-6 col-lg-4 col-xl-3 mt-3'>
            <div className="card">
                <div className="card-body">
                    <div className="d-flex justify-content-between mb-3">
                        <div>
                            <h5 className='text-dark'>{props.name}</h5>
                            <small className='text-muted'>{props.level}. seviye Alzheimer Hastası</small>
                        </div>
                        <button>
                            <i className="fa-duotone fa-trash me-2"></i>
                        </button>
                    </div>
                    <span className="text-muted text-dark">Eposta Adresi</span>
                    <p>{props.email}</p>

                    <span className='text-muted text-dark'>Telefon Numarası</span>
                    <p>{props.email}</p>
                    {/* <span className='text-muted text-dark'>Oynadığı Oyunlar</span>
                    <p className='text-danger'>{props.games}</p> */}
                    <div className="d-flex justify-content-between mb-3">
                        <div>
                            <Link to={`/patient-games/${props.id}`} type="button" className="btn btn-outline-danger" ><i className="fa-light fa-chart-waterfall me-2"></i> İstatistikler</Link>
                        </div>
                       
                        <div>
                            <button onClick={()=>{
                                Swal.fire({
                                    title: 'Yakında...',
                                    text: 'Bu özellik şu anda kullanım dışıdır.',
                                    footer: '<a href="https://www.guvenfuture.com/">Bir problem mi yaşıyorsunuz?</a>',
                                    confirmButtonText: 'Anladım',
                                  })
                            }} className='btn btn-outline-primary'><i className="fa-duotone fa-puzzle me-2"></i>Oyun Ata</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default patientcard