import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { getGameByUserId } from '../../actions/game/getGameByUserId';
import Header from '../layout/header';
import { useNavigate, useParams } from 'react-router';
import GameDetailCard from './gameDetailCard';
import { getSessions } from '../../actions/game/getSessions';
import { ConvertDate } from '../../helpers/timeconverter';


function PatientSessions(props) {
    const { GameId } = useParams();
    const { UserId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getSessions(UserId, GameId))
    }, [dispatch])

    

    return (
        <>

            <Header />
            <div className='container'>
                {props.getSessions.done ?
                    props.getSessions.getSessions.result.map((element, index) => {
                        return(
                            <h2>{ConvertDate(element.CreatedAt)}</h2>
                        )
                    })

                    : null}
                {/* <div className="row">
          {props.getGameByUserId.done ?
            props.getGameByUserId.getGameByUserId.map((element, index) => {

              return (
                <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-3">
                  <GameDetailCard userId={Id} score={element.Scores} game={element.Game} key={index} />
                </div>
              )
            })
            : null}
        </div> */}


            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PatientSessions);
