import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { Link, useNavigate } from 'react-router-dom';
import { logOut } from '../../actions/auth/login';


function Header(props) {


    let navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
  
    function handleLogoutClick() {
        dispatch(logOut())
    };
    if (props.login.isLogin === false) {
        navigate("/login")
    }

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light ps-4 pe-4">
            <div className="container-fluid">
                <Link to={`/`} className="navbar-brand"> <img src="/assets/img/logoalz.svg" width="55" alt="" /><b className='ms-2'>Alzheimer</b> </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <Link className={location.pathname === "/" ? "nav-link active" : "nav-link"} aria-current="page" to="/"><i className="fa-light fa-square-poll-vertical me-2"></i>Hasta Gelişimleri</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={location.pathname === "/reports" ? "nav-link active" : "nav-link"} to="/reports"><i className="fa-regular fa-file-excel me-2"></i>Rapor oluştur</Link>
                        </li>
                        <li className="nav-item">
                            <a className={location.pathname === "/patients" ? "nav-link active" : "nav-link"} href=""><i className="fa-light fa-address-card me-2"></i>Hasta Profilleri</a>
                        </li>
                        <li className="nav-item">
                            <a className={location.pathname === "/patients" ? "nav-link active" : "nav-link"} href="#"><i className="fa-light fa-users me-2"></i>Hasta Ekle</a>
                        </li>
                    </ul>
                    <div className="d-flex">
                        <a onClick={handleLogoutClick} className='text-danger' href="#"><i className="fa-light fa-arrow-right-from-bracket"></i> Çıkış Yap</a>
                    </div>
                </div>
            </div>
        </nav>
    )
}

const mapStateToProps = (state) => {
    return state;
};
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);