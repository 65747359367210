import React, { useEffect, useState } from "react";
import Header from "../layout/header";
import { connect, useDispatch } from "react-redux";
import { getUsers } from "../../actions/user/getUsers";
import { getGames } from "../../actions/game/getGames";
import { getStatistic } from "../../actions/statistic/getStatistic";

function Reports(props) {

  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [games, setGames] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedGame, setSelectedGame] = useState("");
  const [selectedUser, setSelectedUser] = useState("");

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getGames());
  }, [dispatch]);

  useEffect(() => {
    if (props.getUsers.getUsers && props.getGames.getGames) {
      setUsers(props.getUsers.getUsers.result);
      setGames(props.getGames.getGames.result);
    }
  }, [props.getUsers.getUsers, props.getGames.getGames]);

  const handleDownload = (e) => {
    e.preventDefault();
    dispatch(getStatistic(
      selectedGame ? parseInt(selectedGame, 10) : null,
      selectedUser ? parseInt(selectedUser, 10) : null,
      startDate,
      endDate
    ));
  };

  return (
    <div>
      <Header />
      <div className="container mt-5">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Raporlar</h5>
                <p className="card-text">Raporları burada görebilirsiniz.</p>
                <form action="">
                  <div className="form-group mb-3">
                    <label htmlFor="game">Oyun</label>
                    <select className="form-control" id="game" value={selectedGame} onChange={(e) => setSelectedGame(e.target.value)}>
                      <option value="">Seçiniz</option>
                      {games.map((game) => (
                        <option key={game.Id} value={game.Id}>{game.Name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="user">Kullanıcı</label>
                    <select className="form-control" id="user" value={selectedUser} onChange={(e) => setSelectedUser(e.target.value)}>
                      <option value="">Seçiniz</option>
                      {users.map((user) => (
                        <option key={user.Id} value={user.Id}>{user.Name} {user.LastName}</option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="startDate">Başlangıç Tarihi</label>
                    <input type="date" className="form-control" id="startDate" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="endDate">Bitiş Tarihi</label>
                    <input type="date" className="form-control" id="endDate" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                  </div>
                  <br />
                  <button
                    onClick={handleDownload}
                    className="btn btn-outline-primary">
                    <i className="fa fa-download"></i> Raporu İndir
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
